@import "variables";

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }

    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

// add padding for divider '-' for the start- and end-times
// and the target group start- and eng-ages
.padding-top-divider {
  padding-top: 3em;
}

.border-left {
  border-left: $dark-dividers 1px solid;
}

.border-right {
  border-right: $dark-dividers 1px solid;
}

.border-top {
  border-top: $dark-dividers 1px solid;
}

.border-bottom {
  border-bottom: $dark-dividers 1px solid;
}

.text-r {
  text-align: right !important;
}

.text-bold {
  font-weight: $font-weight-bold;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}
