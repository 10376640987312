@import 'colors';

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-family-prompt: 'Prompt';
$font-size: 1rem;
$button-font-size: 0.875rem;

$dark-dividers: rgba(black, 0.12);

$breakpoint-mobile-small: 445px;
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-display: 992px;
$breakpoint-display-large: 1200px;

$padding-l: 1rem;
$padding-xl: 2rem;
$padding-xxl: 3rem;

$auth-background: '/assets/login-background.png';
