.cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  .cdk-drag-handle {
    visibility: hidden;
    padding: 0 15px 0 3px;
  }

  &:hover {
    .cdk-drag-handle {
      visibility: visible;
      color: #999;
    }
  }

  .cdk-drag-disabled {
    &:hover {
      .cdk-drag-handle {
        visibility: hidden;
      }
    }
  }
}

.cdk-drag-placeholder {
  color: transparent !important;
  background: #eee !important;

  &:hover {
    box-shadow: none;
  }

  * {
    color: transparent !important;
    visibility: hidden;
    box-shadow: none;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.08);
  background: white !important;
}

.cdk-drag-animating {
  transition: none; // transform 250ms cubic-bezier(0, 0, 1, 1);
}
